.DateRangePickerDay-Wrapper .MuiDateRangePickerDay-rangeIntervalDayHighlight {
    background-color: unset !important;
}

.date-picker-item-line-through.DateRangePickerDay-Wrapper .Mui-disabled {
    text-decoration: line-through;
}

.date-picker-item-inactive.DateRangePickerDay-Wrapper .Mui-disabled {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.45);
}

.MuiPickersDay-root.Mui-selected {
    background-color: rgba(93,137,179) !important;
    text-shadow: 0px 0px 0px #000;
}

/*.MuiDateRangePickerDay-rangeIntervalDayHighlightStart button {*/
/*    color: #fff !important;*/
/*}*/

.menu-icon svg {
    color: initial !important;
    margin-right: 10px;
}

header ul.dropdown-menu:before,
header ul.dropdown-menu:after {
    content: '' !important;
    display: none !important;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd button {
    /*color: #fff !important;*/
}

.MuiPickersDay-root.Mui-selected {
    color: #fff !important;
}

#mobile-Booking-bar, #list_availability {
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1260;
    height: 92px;
    left: 0;
    box-shadow: 0px -2px 3px -3px rgba(0, 0, 0, 0.65);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #ffffff;
}

.modal-backdrop {
    z-index: 99999 !important;
}

.modal {
    z-index: 100050 !important;
}

span.flex-wrap {
    white-space: normal;
}


.card.border-radius-10 > div,
.card.border-radius-10 > div > span{
    border-top-left-radius: 0px !important;
    -moz-border-top-left-radius: 0px !important;
    -webkit-border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    -moz-border-top-right-radius: 0px !important;
    -webkit-border-top-right-radius: 0px !important;
    border-radius: 0px !important;
}

.hover-push .hover-backdrop {
    opacity: .8;
    background-size: cover!important;
    background-position: center!important;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all .35s;
    transition: all .35s;
}

.hover-push {
    background: #000 !important;
    position: relative;
    cursor: pointer;
}

.hover-push .hover-caption {
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all .35s;
    transition: all .35s;
}

.hover-push:hover .hover-backdrop {
    opacity: .6;
}

.hover-push:hover .hover-caption {
    -webkit-transform: translate3d(0,-20px,0);
    transform: translate3d(0,-20px,0);
}

a.nav-link.btn.btn-sm.border-2.normal.mx-2.my-1.nav-link.text-nowrap {
    padding: 0.6rem 1rem!important;
    max-width: 116.75px;
}

input.guest-input, input.guest-input:disabled {
    color: #000000;
    background: transparent;
    -webkit-appearance:none;
}

.no-gutter {
    --bs-gutter-x: 0rem;
    --bs-gutter-y: 0;
}

.MuiTooltip-popper {
    z-index: 9999999 !important;
}

.text-light-color li button {
    color: #ffffff !important;
}

.Properties__gallery.properties {
    overflow: hidden !important;
}

#pro_distances_section p {
    font-size: 14px;
}

.input-class {
    background: transparent !important;
}

.input-class label {

}

.input-class input {

}

.background-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.4;
}

.Header-Sticky-Top .logo {
    margin: 0px 0px;
}

.navbar {
    border: 0px solid transparent !important;
}

.content-wrapper.text-light-color * {
    color: #ffffff !important;
}

.nav-tabs-dark-mode .nav-tabs-linetriangle:after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}

.nav-tabs-dark-mode.nav-tabs-header.nav-tabs-linetriangle:after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-tabs-dark-mode .nav-tabs-linetriangle > li > a.active:before {
    border-top-color: rgba(255, 255, 255, 0.2);
}

.nav-tabs-dark-mode .nav-tabs-linetriangle > li > a.active:after {
    border-top-color: #333333;
}

.b-border-dark .b-transparent-master {
    border-color: rgba(255, 255, 255, 0.3) !important;
}

.custom-css-grid {
    display: grid;
    /*grid-template-areas:*/
    /*'boxA boxB boxD boxD'*/
    /*'boxC boxC boxD boxD'*/
    /*'boxC boxC boxE boxF';*/
    grid-template-areas:
    'boxA boxA boxD boxE'
    'boxA boxA boxF boxF'
    'boxB boxC boxF boxF';
    gap: 20px;
    column-gap: 20px;
    row-gap: 20px;
    background-color: transparent;
    padding: 0px;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
}

.custom-css-grid > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 0 0;
    font-size: 30px;
}

.custom-css-grid .social-card.full-width {
    width: 100% !important;
    height: 100%;
}

.custom-css-grid .social-card.share .card-content {
    position: relative;
    flex: 1;
    display: flex;
}

.custom-css-grid .card .card-content a {
    display: inline-block;
    height: 100%;
    width: 100%
}

.custom-css-grid .card .card-content a img {
    height: 100%;
    width: 100%
}

.custom-css-grid .card img {
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover !important;
}

#mobile-Booking-bar .d-flex {
    display: flex !important;
    gap: 0px 10px;
}

.faded-property {
    opacity: 0.75;
}

.property-custom-css-grid {
    display: grid;
    grid-template-areas:
    'boxA boxA boxA boxB boxC'
    'boxA boxA boxA boxB boxC'
    'boxA boxA boxA boxB boxC'
    'boxA boxA boxA boxD boxE'
    'boxA boxA boxA boxD boxE'
    'boxA boxA boxA boxD boxE';
    gap: 5px;
    column-gap: 5px;
    row-gap: 5px;
    background-color: transparent;
    padding: 0px;
}


.property-custom-css-grid > div.boxA .card {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.property-custom-css-grid > div.boxC .card {
    border-top-right-radius: 12px;
}

.property-custom-css-grid > div.boxE .card {
    border-bottom-right-radius: 12px;
}

.property-custom-css-grid > div.boxA {
    grid-area: boxA;
}

.property-custom-css-grid > div.boxB {
    grid-area: boxB;
}

.property-custom-css-grid > div.boxC {
    grid-area: boxC;
}

.property-custom-css-grid > div.boxD {
    grid-area: boxD;
}

.property-custom-css-grid > div.boxE {
    grid-area: boxE;
}

.property-custom-css-grid > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 0 0;
    font-size: 30px;
}

.property-custom-css-grid .social-card.full-width {
    width: 100% !important;
    height: 100%;
    box-shadow: none;
    border: none;
    background: transparent;
}

.property-custom-css-grid .social-card.share .card-content {
    position: relative;
    flex: 1;
}

.property-custom-css-grid .card .card-content a {
    display: inline-block;
    height: 100%;
    width: 100%
}

.property-custom-css-grid .card .card-content img {
    height: 100%;
    width: 100%;
}

.property-custom-css-grid .card img {
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover !important;
}

.form-group-default.has-error {
    border-color: #b91e1e;
}

.text-danger {
    color: #b91e1e !important;
}

/*.d-none {*/
/*    display: none !important;*/
/*}*/

/*.d-inline-block {*/
/*    display: inline-block !important;*/
/*}*/

.nav-tabs-header {
    overflow: auto;
}

.form-group-default.focused {
    z-index: 9;
}

.hover-zoom .inner {
    right: 0;
    left: auto;
}

.panel-item.hover-zoom {
    display: inline-block;
}

.navbar-nav li.nav-item:nth-child(1) {
    margin-left: 20px
}

.navbar-nav .dropdown-menu.megamenu {
    /*display: flex;*/
    flex-wrap: wrap;
    width: fit-content;
    margin-left: 0;
    left: 0;
    min-width: 380px;
    flex-direction: row;
}

.navbar-nav .dropdown-menu.megamenu.show {
    display: flex;
}

header ul.dropdown-menu.megamenu > li.arrow {
    margin-top: 20px;
    left: calc( 25% - 7px );
    padding: 0;
}

header .dropdown-article-menu.megamenu {
    margin-top: 0 !important;
}

header .dropdown-menu.megamenu > li {
    opacity: 1;
    line-height: 24px;
    transition: all 0.1s linear 0.1s;
    width: 50%;
    padding: 5px 25px;
    margin: 0;
}

header .dropdown-menu.megamenu:before {
    width: calc(25% - 7px);
}

header .dropdown-menu.megamenu:after {
    width: calc(75% - 7px);
}

.Properties.row .swiper-slide {
    text-align: initial !important;;
}

.Properties.row .swiper-slide h5 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.properties-related .Properties__gallery.properties .swiper-button-next,
.properties-related .Properties__gallery.properties .swiper-button-prev,
.properties-related .Properties__gallery.properties .swiper-pagination {
    display: none !important;
}

.chat-icons {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 99999;
}

.chat-icons .icon > span {
    margin-left: 8px;
    color: #ffffff;
    font-weight: 400;
}

.chat-icons .icon {
    display: block;
    border-top-left-radius: 4px;
    color: #ffffff;
    padding: 8px 16px;
}

.chat-icons .icon.viber {
    background: #685ab0;
    border-top-left-radius: 8px;
}

.chat-icons .icon.whatsapp {
    background: #4fce5d;
    border-bottom-left-radius: 8px;
}

.pacer {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 99999;
    height: 4px;
}

.modal.border-0 .modal-content {
    border: none !important;
}

.modal.xxl .modal-dialog {
    width: 80%;
    max-width: 800px;
}

.modal.transparent-content .modal-content {
    background: transparent;
}

.close_button {
    max-width: 250px;
    border-radius: 8px;
    margin: 15px auto;
}

.properties-related .Properties.Properties-Shadow {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.fs-36 {
    font-size: 36px !important;
}

label.searchbutton {
    padding-top: 10px;
    padding-right: 17px;
    padding-left: 10px;
}

.navbar-toggle-collapse {
    flex-grow: 1;
}

.header a {
    flex-grow: 1;
}

.Modal-Header .modal-dialog#modal-menu .modal-content .modal-header:after {
    content: "" !important;
    background-image: url('https://loggia-cdn.s3.eu-central-1.amazonaws.com/image-holiways-5-04fc0b0372e2a0ee4527f507bbd68d1a-6d7674.png') !important;;
}

.fancybox__container {
    z-index: 155555 !important;
}

/*header .dropdown-menu:before,*/
/*header .dropdown-menu:after {*/
/*    content: none;*/
/*}*/


@media (max-width: 768px) {
    .zig-photo-container {
        min-height: 300px;
    }

    .modal.xxl .modal-dialog {
        margin: 0;
        width: 100%;
        max-width: 800px;
    }

    #search-wrapper {
        flex-grow: 1;
    }
    /*.property-custom-css-grid {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    width: 100%;*/
    /*}*/

    .jumbotron {
        max-height: 500px;
    }
}

@media (max-width: 576px) {

    .custom-css-grid {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .property-custom-css-grid {
        grid-template-areas:
        "boxA";
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
    }

    .property-custom-css-grid > div.boxA .card {
        border-radius: 12px;
    }

    .property-custom-css-grid > div.boxB,
    .property-custom-css-grid > div.boxC,
    .property-custom-css-grid > div.boxD,
    .property-custom-css-grid > div.boxE {
        display: none;
    }

    .xs-no-padding {
        padding: 0 !important;
    }

    .xs-text-center {
        text-align: center !important;
    }

    /*#quick_search {*/
    /*    padding: 15px 20px 10px 10px;*/
    /*}*/

    label.searchbutton {
        padding-top: 15px;
        padding-right: 17px;
        padding-left: 0px;
    }
}